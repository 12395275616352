import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { TIdentity } from '@components/types';
import { login, getprofile, socialLogin } from '@api/authentication';
import { isLoggedIn } from '@recoil/atoms';
import { useRouter } from 'next/router';
import { useRecoilState } from 'recoil';
import { FcGoogle } from 'react-icons/fc';
import { useGoogleLogin } from '@react-oauth/google';
import { Input } from 'antd';

interface Props {
  setIsSignUp?: Function;
  setShowModel?: Function;
}

interface Window {
  fbq: (event: string, ...args: any[]) => void;
}

export const SignIn: React.FC<Props> = ({ setIsSignUp, setShowModel }) => {
  const [message, setMessage] = useState('');
  const [state, setState] = useState < TIdentity > ();
  const [isLogin, setIsLogin] = useRecoilState(isLoggedIn);
  const [showPassword, setShowPassword] = React.useState(false);
  const [userLoggedIn, setUserLoggedIn] = useRecoilState(isLoggedIn);
  const router = useRouter();
  const [userType, setUserType] = useState(null);

  const handleInput = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [evt.target.name]: evt.target.value });
    setMessage('');
  };

  const handleLoginPromise = (promise: Promise<any>) => {
    promise
      .then((result) => {
        console.debug(result);
        localStorage.setItem('token', result.access_token);
        const res_profile = getprofile();
        res_profile.then((profile) => {
          console.debug(profile);
          localStorage.setItem('profile', JSON.stringify(profile));
          setUserType(JSON.parse(localStorage.getItem('profile'))?.is_business);
          setShowModel && setShowModel(false);
          setUserLoggedIn(true);
        });
      })
      .catch((result) => {
        console.debug(result);
        setMessage(result.response.data.detail);
      });
  };

  const handleLogin = async () => {
    if (
      state?.username?.length === undefined ||
      state?.username?.length === 0
    ) {
      setMessage('Please provide email or phone number');
      return;
    }
    if (
      state?.password?.length === undefined ||
      state?.password?.length === 0
    ) {
      setMessage('Please provide password');
      return;
    }
    if (state !== undefined) {
      const res = login(state);
      handleLoginPromise(res);
      // window?.fbq('track', 'Lead', { 'url': router?.asPath, 'name':"enquiry" })
    }
  };

  const handleSocialLogin = (provider: string) => {
    const handle = async (response: any) => {
      const res = socialLogin(response.access_token);
      handleLoginPromise(res);
    };
    return handle;
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) =>
      handleSocialLogin('google')(tokenResponse),
    onError: (errorResponse) => console.debug(errorResponse),
  });

  useEffect(() => {
    if (userType != null) {
      setIsLogin(true);
      let nextLink =
        router.query?.next !== '' && router.query?.next !== null
          ? (router.query?.next as string)
          : '/';
      router.push(nextLink || '/');
    }
  }, [userType]);

  return (
    <div className="w-full flex flex-col items-center justify-center lt-sm:overflow-hidden font-manrope">
      <div className="flex flex-col gap-2 w-full">
        <div className="flex flex-col ">
          <div className="flex justify-center pb-8">
            <h2 className="text-xl md:text-2xl font-semibold text-neutral-700 text-center font-manrope">
              Login
            </h2>
          </div>
          <div>
            <label className="block">
              <span className="block md:text-base text-sm font-semibold text-neutral-700 line-clamp-2 pb-1">
                Email or Mobile Number
              </span>
              <Input
                type="text"
                placeholder="Email or Phone"
                name="username"
                value={state?.username}
                onChange={(evt) => handleInput(evt)}
              />
            </label>
          </div>
          <div>
            <label className="block mt-4">
              <span className="block md:text-base text-sm font-semibold text-neutral-700 line-clamp-2 pb-1">
                Password
              </span>
              <Input
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Enter your Password"
                required
                value={state?.password}
                onChange={(evt) => handleInput(evt)}
              />
            </label>
          </div>
          <div>
            <div className="text-sm text-left">
              <p>
                <Link href="/account/forgot-password">
                  <span className="font-semibold py-2 text-brand-500">
                    Forgot Password
                  </span>
                </Link>
              </p>
            </div>
          </div>
          <div className="col-span-6">
            <p className="text-error-900 font-semibold text-center">
              {message}
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <div className="bg-neutral-50 mt-4 text-right">
              <button
                onClick={() => {
                  handleLogin();
                }}
                className="block w-full inline-flex justify-center font-manrope rounded-md border border-transparent bg-brand-500 py-1 px-4 text-base font-medium text-neutral-50 shadow-sm hover:bg-brand-900 focus:outline-none focus:ring-brand-500 focus:ring-2 focus:bg-brand-500 focus:ring-offset-2"
              >
                Login
              </button>
            </div>
            <div className="flex items-center justify-center text-black font-medium">
              OR
            </div>
            <div className="">
              <button
                className="w-full text-business-300 flex items-center justify-center py-1 gap-2 bg-neutral-100 whitespace-nowrap border border-solid text-base border-neutral-300 rounded-md font-semibold"
                onClick={() => loginGoogle()}
              >
                <FcGoogle size={20} />

                <span className="font-manrope text-base font-medium">
                  Login with Google
                </span>
              </button>
            </div>
            <div></div>
            <div className="text-sm hidden md:block">
              <p className="font-semibold text-base text-neutral-600">
                Don’t have an account?{' '}
                <button
                  className="cursor-pointer"
                  onClick={() => {
                    if (setIsSignUp) {
                      setIsSignUp(true);
                    } else {
                      router.push('/signup');
                    }
                  }}
                >
                  <span className="font-semibold font-manrope text-base text-brand-500">
                    CREATE ACCOUNT
                  </span>
                </button>
              </p>
            </div>
            <div className="text-sm md:hidden block">
              <p className="font-semibold text-base text-neutral-600">
                Don’t have an account?{' '}
                <Link href="/signup">
                  <span className="font-semibold text-base text-brand-500">
                    CREATE ACCOUNT
                  </span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
